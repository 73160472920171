import React, {
  ReactElement,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import BedIcon from '../../assets/svg/bed.svg'
import ShowerIcon from '../../assets/svg/shower.svg'
import UnitSizeIcon from '../../assets/svg/unit-size-white.svg'
import CaretRightIcon from '../../assets/svg/caret-right.svg'
import TagImageWebsite from '../../assets/images/WEBSITE Home Page Tagline white Feb 2024.png'
import TagImageMobile from '../../assets/images/MOBILE Home Page tagline white Feb 2024.png'
import { isMP4 } from '../../services/validations'
import './HeroBanner.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'

export type HeroBannerTypes = {
  title: string
  shortDescription?: string
  type: string
  slug: string
  category: string
  location?: string
  imageGallery: (string | ImageDataLike)[]
  datePublished?: string
  priceRange?: string
  floorAreaSize?: string
  bedroom?: number[]
  bathroom?: number[]
}

type HeroBannerPropTypes = {
  featuredList: HeroBannerTypes[]
}

const HeroBanner = ({ featuredList }: HeroBannerPropTypes): ReactElement => {
  const sliderRef = useRef<Slider>(null)
  const [currentFeatured, setCurrentFeatured] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [
    displayFeatured,
    setDisplayFeatured,
  ] = useState<HeroBannerTypes | null>(
    featuredList.length ? featuredList[0] : null,
  )

  useEffect(() => {
    setDisplayFeatured(
      featuredList.length ? featuredList[currentFeatured] : null,
    )
  }, [featuredList, currentFeatured])

  const goToSlide = useCallback(
    (index: number) => {
      if (sliderRef?.current?.slickGoTo) {
        sliderRef.current.slickGoTo(index)
      }
    },
    [sliderRef],
  )

  const handleBeforeChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex)
    },
    [],
  )

  const handlePropertyNext = useCallback(() => {
    if (currentFeatured === featuredList.length - 1) {
      setCurrentFeatured(0)

      return
    }
    setCurrentFeatured(currentFeatured + 1)
  }, [currentFeatured, featuredList])

  if (!displayFeatured) {
    return <></>
  }

  const displaySlide = (url: string | ImageDataLike): any => {
    if (typeof url === 'string' && isMP4(url)) {
      return (
        <video
          className="hero-banner-slider-video"
          key={url}
          autoPlay
          muted
          loop
        >
          <source src={url} type="video/mp4" />
        </video>
      )
    } else {
      return (
        <GatsbyImage
          className="banner-slider-image"
          image={getImage(url as ImageDataLike)!}
          alt="banner slide image"
        />
      )
    }
  }

  return (
    <div className="hero-banner">
      <Link
        to={`/properties/${displayFeatured.slug}/`}
        className="hero-banner-link"
      />
      <Slider
        swipeToSlide={false}
        arrows={false}
        dots={false}
        fade
        easing="ease"
        className="hero-banner-slider"
        beforeChange={handleBeforeChange}
        autoplay={true}
        ref={sliderRef}
      >
        {displayFeatured.imageGallery.map((item) => displaySlide(item))}
      </Slider>
      <div className="hero-banner-content-container">
        <div className="hero-banner-content">
          <div className="hero-banner-layout-helper">
            <Link
              to={`/properties/${displayFeatured.slug}/`}
              className="helper-link"
            />
          </div>
          <div className="hero-banner-current-content">
            <div className="hero-banner-current-logo">
              <img
                src={TagImageWebsite}
                className="hero-banner-current-logo-tag-line desktop"
                alt="logo"
              />
              <img
                src={TagImageMobile}
                className="hero-banner-current-logo-tag-line mobile"
                alt="logo"
              />
            </div>
            <div className="hero-banner-current-brand-desc">
              With Avida as your home, you feel safe and secured that you can
              face whatever life has in store.
            </div>
            <div className="hero-banner-current-description">
              <div className="hero-banner-current-header">
                <div className="hero-banner-current-title">
                  <h3>{displayFeatured.title}</h3>
                  <div className="hero-banner-current-location">
                    {displayFeatured.location}
                  </div>
                </div>
                <p>{displayFeatured.type}</p>
              </div>
              <div className="hero-banner-current-details">
                <p className="hero-banner-current-address">
                  {displayFeatured.shortDescription}
                </p>
                {displayFeatured.category === 'property' &&
                  displayFeatured.priceRange && (
                    <p className="hero-banner-current-price-range">
                      Price Range <br />
                      <span className="hero-banner-current-price-value">
                        {displayFeatured.priceRange}
                      </span>
                    </p>
                  )}
                {displayFeatured.datePublished && (
                  <p className="hero-banner-current-price-range">
                    Date Published <br />
                    <span className="hero-banner-current-price-value">
                      {displayFeatured.datePublished}
                    </span>
                  </p>
                )}
              </div>
            </div>
            {displayFeatured.category === 'property' && (
              <div className="hero-banner-current-spec">
                <span>
                  <img src={UnitSizeIcon} alt="unit icon" />{' '}
                  {displayFeatured.floorAreaSize}
                </span>
                <span>
                  <img src={BedIcon} alt="bedroom icon" />{' '}
                  {displayFeatured.bedroom?.join(',')} Bedrooms
                </span>
                <span>
                  <img src={ShowerIcon} alt="shower icon" />{' '}
                  {displayFeatured.bathroom?.join(',')} Bathrooms
                </span>
              </div>
            )}
            <div className="slider-nav-container">
              {displayFeatured.imageGallery.map((url, index) => (
                <button type="button" onClick={(): void => goToSlide(index)}>
                  {typeof url === 'string' && isMP4(url) ? (
                    <video
                      className={`slider-nav${
                        currentSlide === index ? ' active' : ''
                      }`}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={url} type="video/mp4" />
                    </video>
                  ) : (
                    <GatsbyImage
                      image={getImage(url as ImageDataLike)!}
                      alt="sliderNav"
                      className={`slider-nav${
                        currentSlide === index ? ' active' : ''
                      }`}
                    />
                  )}
                </button>
              ))}
            </div>
            <Link
              to={`/properties/${displayFeatured.slug}/`}
              target="_blank"
              className="hero-banner-current-link"
            >
              View Details
            </Link>
          </div>
          <button
            type="button"
            className="next-item"
            onClick={handlePropertyNext}
          >
            <span>
              Next{' '}
              {
                featuredList[
                  currentFeatured === featuredList.length - 1
                    ? 0
                    : currentFeatured + 1
                ].category
              }
            </span>
            <span className="next-item-name">
              {
                featuredList[
                  currentFeatured === featuredList.length - 1
                    ? 0
                    : currentFeatured + 1
                ].title
              }
            </span>
            <img src={CaretRightIcon} alt="caret right" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
