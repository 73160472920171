/* eslint-disable no-nested-ternary */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import loadable from '@loadable/component'
import { ImageDataLike } from 'gatsby-plugin-image'
import HeroBanner from '../../components/HeroBanner/HeroBanner'
import Layout from '../../layouts/Layout/Layout'
import PassionImage from '../../assets/images/pursuit-of-passion.webp'
import HomePageBackground from '../../assets/images/home-page-background.png'
import Seo from '../../components/Seo/Seo'
import './Main.scss'
import { isMP4 } from '../../services/validations'
import AccessAyalaModal from '../../assets/images/access ayala/Popup A Green Copy.png'
import CustomDiv from '../../components/ClosableDiv/Closable'

type PropertyCardTypes = {
  propertyName: string
  propertyType: string
  featuredImage: string
  slug: string
  priceRange: string
  propertyLocation: string
  floorAreaSize: string
  optimizedFeaturedImg: ImageDataLike
}

type ArticleCardTypes = {
  title: string
  category: string
  slug: string
  featuredImage: string
  datePublished: string
  optimizedFeaturedImg: ImageDataLike
}

const PropertySearch = loadable(() =>
  import('../../components/PropertySearch/PropertySearch'),
)
const ArticleCard = loadable(() =>
  import('../../components/ArticleCard/ArticleCard'),
)
const PropertyCard = loadable(() =>
  import('../../components/PropertyCard/PropertyCard'),
)
const SliderSection = loadable(() =>
  import('../../components/SliderSection/SliderSection'),
)

type HeroBannerPropertyTypes = {
  propertyName: string
  propertyType: string
  slug: string
  priceRange: string
  floorAreaSize: string
  propertyLocationSpecific: string
  homeBanner?: {
    description: string
    image: string
    videoLink: string
    title: string
  }
  optimizedBannerImg: ImageDataLike
  gallery: {
    featured: boolean
    description: string
    url: string
    type: string
    optimizedGalleryImg: ImageDataLike
  }[]
}

type HeroBannerArticleTypes = {
  title: string
  shortDescription: string
  slug: string
  category: string
  featuredImage: string
  datePublished: string
}

type PropertyTypes = {
  propertyName: string
  propertyType: string
  featuredImage: string
  slug: string
  priceRange: string
  floorAreaSize: string
  propertyLocation: string
  optimizedFeaturedImg: ImageDataLike
}

type ArticleTypes = {
  title: string
  slug: string
  category: string
  featuredImage: string
  datePublished: string
  optimizedFeaturedImg: ImageDataLike
}

type MainPropTypes = {
  data: {
    heroBannerProperties: { nodes: HeroBannerPropertyTypes[] }
    heroBannerNewsAndEvents: { nodes: HeroBannerArticleTypes[] }
    heroBannerAnnouncements: { nodes: HeroBannerArticleTypes[] }
    heroBannerPursuitOfPassion: { nodes: HeroBannerArticleTypes[] }
    featuredProperties: { nodes: PropertyTypes[] }
    featuredNewsAndEvents: { nodes: ArticleTypes[] }
    featuredPursuitOfPassion: { nodes: ArticleTypes[] }
    SeoTags: {
      seoHomeTitle: string
      seoHomeDescription: string
    }
  }
}

const Main = ({
  data: {
    heroBannerProperties: { nodes: heroBannerProperties },
    heroBannerNewsAndEvents: { nodes: heroBannerNewsAndEvents },
    heroBannerAnnouncements: { nodes: heroBannerAnnouncements },
    heroBannerPursuitOfPassion: { nodes: heroBannerPursuitOfPassion },
    featuredProperties: { nodes: featuredProperties },
    featuredNewsAndEvents: { nodes: featuredNewsAndEvents },
    featuredPursuitOfPassion: { nodes: featuredPursuitOfPassion },
    SeoTags: { seoHomeTitle, seoHomeDescription },
  },
}: MainPropTypes): ReactElement => {
  const [bannerFeaturedData] = useState([
    ...heroBannerProperties.map((property) => ({
      title: property.homeBanner?.title || property.propertyName,
      shortDescription: property.homeBanner?.description,
      type: property.propertyType,
      slug: property.slug,
      category: 'property',
      priceRange: property.priceRange,
      floorAreaSize: property.floorAreaSize,
      location: property.propertyLocationSpecific,
      imageGallery: ([
        property.optimizedBannerImg,
        ...property.gallery
          .filter(
            (galleryItem) =>
              galleryItem.type === 'image' || galleryItem.type === 'mp4',
          )
          .map(({ type, url, optimizedGalleryImg }) => {
            if (type === 'image') {
              return optimizedGalleryImg
            }

            return url
          }),
      ]
        .filter((galleryItem) => galleryItem)
        .slice(0, 3) || []) as (string | ImageDataLike)[],
    })),
    ...heroBannerNewsAndEvents.map((article) => ({
      ...article,
      type: article.category,
      imageGallery: [article.featuredImage],
    })),
    ...heroBannerAnnouncements.map((article) => ({
      ...article,
      type: article.category,
      imageGallery: [article.featuredImage],
    })),
    ...heroBannerPursuitOfPassion.map((article) => ({
      ...article,
      type: article.category,
      imageGallery: [article.featuredImage],
    })),
  ])
  const [isStick, setStick] = useState<boolean>(false)
  // const [closeModal, setCloseModal] = useState(true)

  const sliderPropertyListMapper = useCallback(
    (property: PropertyCardTypes): ReactElement => (
      <PropertyCard key={property.slug} data={property} />
    ),
    [],
  )

  // useEffect(() => {
  //   if (sessionStorage.getItem('close')) {
  //     setCloseModal(true)
  //   } else {
  //     setCloseModal(false)
  //   }
  // }, [])

  // const handleClick = () => {
  //   sessionStorage.setItem('close', 'true')
  //   setCloseModal(true)
  // }

  const sliderNewsAndEventsListMapper = useCallback(
    (event: ArticleCardTypes): ReactElement => (
      <ArticleCard
        key={event.slug}
        data={event}
        url={`/news-and-events/${event.slug}/`}
      />
    ),
    [],
  )

  const sliderPursuitOfPassionListMapper = useCallback(
    (blog: ArticleCardTypes): ReactElement => (
      <ArticleCard
        key={blog.slug}
        data={blog}
        url={`/pursuit-of-passion/${blog.slug}/`}
      />
    ),
    [],
  )

  const getMetaImage = (): string => {
    const images =
      bannerFeaturedData &&
      bannerFeaturedData[0]?.imageGallery?.filter(
        (link) => !isMP4(typeof link === 'string' ? link : ''),
      )
    return images ? (typeof images[0] === 'string' ? images[0] : '') : ''
  }

  return (
    <Layout
      isStick={isStick}
      setStick={setStick}
      navButtonVariant="burger"
      includeNewsLetter
    >
      <Seo
        title={seoHomeTitle || 'Avida Land'}
        ogMetaData={{
          title: seoHomeTitle || 'Avida Land',
          description:
            seoHomeDescription ||
            'Seeing unprecedented growth among the Filipino middle class, Ayala Land – from a portfolio of properties that were primarily high-end – expanded its scope to cater to the needs of this booming sector',
          image: getMetaImage(),
        }}
        jsonData={{
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment',
        }}
      />
      <HeroBanner featuredList={bannerFeaturedData} />
      {/* <>
        {!closeModal ? (
          <CustomDiv onClose={(): any => handleClick()}>
            <Link to="https://access.ayalaland.com/" className="access-ayala">
              <button
                type="button"
                className="access-ayala-close-button"
                onClick={(e): void => {
                  e.preventDefault()
                  handleClick()
                }}
              >
                <span>&times;</span>
              </button>
              <img src={AccessAyalaModal} alt="" />
            </Link>
          </CustomDiv>
        ) : null}
      </> */}
      <div className="main-search-title-container">
        <h1 className="seo-hidden">
          {seoHomeTitle || 'Avida Homes, House & Lots and Condos'}
        </h1>
        <h2 className="main-search-title">
          <small>Discover your </small> Dream Home
        </h2>
      </div>
      <PropertySearch
        display
        isStick={isStick}
        variant="sticky"
        onSearchClose={setStick}
      />

      <div
        className="main-container"
        style={{
          background: `url('${HomePageBackground}') no-repeat scroll center top / contain`,
        }}
      >
        <SliderSection
          title="Featured Properties"
          subTitle="Check out some of our"
          description="Find a home that caters to your needs with Avida’s wide range of product offerings across the country."
          slideList={featuredProperties}
          listMapper={sliderPropertyListMapper}
          link="properties"
        />
        <SliderSection
          titleImage={PassionImage}
          description="Be inspired by these stories of people pursuing their passions, of places to broaden your mind, and of holistic lifestyle interests for a life well lived."
          slideList={featuredPursuitOfPassion}
          listMapper={sliderPursuitOfPassionListMapper}
          link="pursuit-of-passion"
        />
        <SliderSection
          title="NEWS & EVENTS"
          description="Be in the know about anything and everything Avida—from news and updates, to events and other initiatives."
          slideList={featuredNewsAndEvents}
          listMapper={sliderNewsAndEventsListMapper}
          link="news-and-events"
        />
      </div>
    </Layout>
  )
}

export default Main

export const pageQuery = graphql`
  query {
    heroBannerProperties: allProperty(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerPropertyFields
      }
    }
    heroBannerNewsAndEvents: allNewsAndEvents(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerNewsAndEventsFields
      }
    }
    heroBannerAnnouncements: allAnnouncement(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerAnnouncementFields
      }
    }
    heroBannerPursuitOfPassion: allPursuitOfPassion(
      filter: { homeBanner: { image: { regex: "/[a-zA-Z]+/i" } } }
    ) {
      nodes {
        ...MainPageHeroBannerPursuitOfPassionFields
      }
    }
    featuredProperties: allProperty(
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        ...MainPagePropertyFields
      }
    }
    featuredNewsAndEvents: allNewsAndEvents(
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        ...MainPageNewsAndEventsFields
      }
    }
    featuredPursuitOfPassion: allPursuitOfPassion(
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        ...MainPagePursuitOfPassionFields
      }
    }
    SeoTags: homePageSeo {
      seoHomeDescription
      seoHomeTitle
    }
  }
`
